import React from "react"
import { css } from "@emotion/core"
import PropTypes from "prop-types"

const Button = ({ children }) => (
  <div
    css={css`
      button {
        background-color: transparent;
        color: white;
        font-size: 0.8em;
        font-weight: 300;
        letter-spacing: 0.12em;
        text-transform: uppercase;
        padding: 10px 20px;
        border: 2px solid white;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 1px;
        transition: background-color 150ms linear;
      }

      button:hover {
        background-color: white;
        cursor: pointer;
        color: black;
      }
    `}
  >
    <button type="submit">
      <span>{children}</span>
    </button>
  </div>
)

Button.propTypes = {
  children: PropTypes.element.isRequired,
}

export default Button
