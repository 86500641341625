import React from "react"
import { css } from "@emotion/core"
import { FormattedMessage } from "react-intl"
import SEO from "../components/seo"
import Layout from "../components/layout"
import Button from "../components/utils/button"

const Donate = () => (
  <Layout>
    <SEO title="Donate" />
    <div
      css={css`
        margin-top: 50%;
        margin: 20px auto;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 40vh;
      `}
    >
      <a
        css={css`
          font-size: 20px;
          :hover {
            text-decoration: none;
          }
        `}
        target="_blank"
        rel="noopener noreferrer"
        href="https://secure.squarespace.com/checkout/donate?donatePageId=5bc627f90852298e0d320f3d&ss_cid=98821902-8f9b-4383-bf30-abb211822fb7&ss_cvr=f522a8b6-588a-417b-b949-6da49a78d160%7C1583903323850%7C1585024825658%7C1585072215232%7C18"
      >
        <Button>
          <FormattedMessage id="donate.btn_text" />
        </Button>
      </a>
    </div>
  </Layout>
)

export default Donate
